import { Link } from "gatsby"
import React from "react"
import { IoIosArrowForward } from "react-icons/io"
import { RoutePathNode } from "../../hooks/useRouteTree"
import * as styles from "./BreadCrumbs.module.scss"

type BreadCrumbsProps = { nodes: (RoutePathNode | null)[] }

const BreadCrumbs = (props: BreadCrumbsProps) => {
  //console.log('Rendering BreadCrumbs', props)
  return (
    <div className={styles.container}>
      {props.nodes.reverse().map((node, i) => {
        if (!node) return null
        return (
          <React.Fragment key={node.path}>
            {!!i && <IoIosArrowForward className={styles.delim} />}
            {node.path ? <Link to={node.path}>{node.label}</Link> : <span>{node.label}</span>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default BreadCrumbs
