import React, { useEffect, useRef, useState } from "react"
import { FaFacebookF, FaLinkedinIn, FaShareAlt, FaTwitter } from "react-icons/fa"
import { IoLink } from "react-icons/io5"
import { MdEmail } from "react-icons/md"
import { CSSTransition } from "react-transition-group"
import { isBrowser } from "../../utils"
import ExternalLink from "../atoms/ExternalLink"
import * as styles from "./ShareLinks.module.scss"

type ShareLinksProps = {
  title: string
  text: string
  url?: string
  shareLabel: string
  linkLabel: string
  emailLabel: string
}

const ShareLinks = (props: ShareLinksProps) => {
  //console.log('Rendering ShareLinks', props)

  const [open, setOpen] = useState(false)
  const container = useRef(null)

  /* Jaettavat sisällöt */
  const url = props.url || isBrowser ? window.location.href : undefined
  const text = props.text

  const clickHandler = () => {
    if (navigator.share && url) {
      navigator
        .share({
          title: props.title,
          text: text,
          url: url,
        })
        .then(() => {
          console.info("Successfully shared")
        })
        .catch(error => {
          console.error("Something went wrong sharing the blog", error)
        })
    } else {
      setOpen(!open)
    }
  }

  function winClick(e: MouseEvent) {
    if (open) {
      // find if clicked inside current nav tree, else close navigation
      let navClick = false
      let cur = e.target as HTMLElement
      while (cur) {
        //console.log(cur);
        if (cur === container.current) {
          navClick = true
          break
        }
        cur = cur.parentElement as HTMLElement
      }

      if (!navClick) setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener("click", winClick)

    return () => {
      window.removeEventListener("click", winClick)
    }
  })

  // TODO logic on "do we have needed info to render something" to include text, title etc? Render only links that work with current props values?
  if (!url) return null

  return (
    <div className={styles.container} ref={container}>
      <button className={styles.button} onClick={clickHandler}>
        {props.shareLabel} <FaShareAlt />
      </button>
      <CSSTransition classNames="fade" in={open} timeout={500} unmountOnExit>
        <div className={styles.links}>
          <a className={styles.link} onClick={() => navigator.clipboard.writeText(url)} tabIndex={0}>
            <span>{props.linkLabel}</span>
            <IoLink />
          </a>
          <a className={styles.link} href={encodeURI(`mailto:subject=${props.title}&body=${text}%0A%0A${url}`)}>
            <span>{props.emailLabel}</span>
            <MdEmail />
          </a>
          <ExternalLink className={styles.link} href={encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)}>
            <span>Facebook</span>
            <FaFacebookF />
          </ExternalLink>
          {/* <a className={styles.link} href="">
            <span>Instagram</span>
            <FaInstagram />
          </a> */}
          <ExternalLink
            className={styles.link}
            href={encodeURI(
              `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${props.title}&summary=${text}`
            )}>
            <span>LinkedIn</span>
            <FaLinkedinIn />
          </ExternalLink>
          {/* <ExternalLink className={styles.link} href={`https://pinterest.com/pin/create/button/?url=${imagesource}&media=${imageurl}&description=${teksti}`}>
            <span>Pinterest</span>
            <FaPinterest />
          </ExternalLink> */}
          <ExternalLink
            className={styles.link}
            href={encodeURI(`https://twitter.com/intent/tweet?text=${props.title}%0A%0A${text}%0A%0A${url}`)}>
            <span>Twitter</span>
            <FaTwitter />
          </ExternalLink>
        </div>
      </CSSTransition>
    </div>
  )
}

export default ShareLinks
