import { graphql, useStaticQuery } from "gatsby"
import { useContext } from "react"
import { PageContext } from "../context/PageContext"
import { localeValue } from "../utils"
import useRoutePath from "./useRoutePath"
import useWebsite from "./useWebsite"

/**
 * Palauttaa yksittäisen routen hierarkia _ylöspäin_ juurisivuun (Etusivu/Hemsidan) asti.
 * Tälle ei pitäisi olla tarvetta. Tehokkaampaa on noutaa vain halutun routen hierarkia
 * jo GraphQL vaiheessa.
 */

export type RoutePathNode = {
  label: string
  path: string
}

type TreeNode = Queries.DatoCmsRouteTreeQuery["allDatoCmsRoute"]["nodes"][number]

export const useRouteTree = (routeId?: string) => {
  const { rootRoute } = useWebsite()
  const getRoutePath = useRoutePath()
  const { locale } = useContext(PageContext)

  // Kovakoodataan etusivun nimi.
  // const frontPageLabel = t("common", "front_page", "Front page")
  // TODO!!! Väliaikainen käännös väärässä paikassa!
  const frontPageLabel = locale === "fi" ? "Etusivu" : "Front page"

  const data = useStaticQuery<Queries.DatoCmsRouteTreeQuery>(
    graphql`
      fragment RouteTreeRouteFields on DatoCmsRoute {
        page {
          ... on DatoCmsPage {
            # alias to make JSON payload smaller (staticquery results end up in client bundles)
            t: _allTitleLocales {
              locale
              value
            }
          }
          ... on DatoCmsProductLandingPage {
            t: _allTitleLocales {
              locale
              value
            }
          }
          ... on DatoCmsProductSearchPage {
            t: _allTitleLocales {
              locale
              value
            }
          }
        }
        originalId
        #locales
        slug
      }

      query DatoCmsRouteTree {
        allDatoCmsRoute {
          nodes {
            ...RouteTreeRouteFields
            treeParent {
              ...RouteTreeRouteFields
              treeParent {
                ...RouteTreeRouteFields
                treeParent {
                  ...RouteTreeRouteFields
                  treeParent {
                    ...RouteTreeRouteFields
                    treeParent {
                      ...RouteTreeRouteFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  // console.debug("useRouteTree()", data)

  // koska hookia ei voi käyttää konditionaalisesti ja aina käyttäjä ei voi olla varma sille annetuista arvoista
  if (!routeId) {
    return []
  }

  // route nodes
  const { nodes } = data.allDatoCmsRoute

  // find the correct root route node from all route trees (global, nordic, europe, america, etc)
  const route = nodes.find(rootNode => rootNode.originalId === routeId /*&& rootNode.locale === locale*/)

  const getRouteTreePaths = (routeTreeNode: TreeNode | null): (RoutePathNode | null)[] => {
    if (!routeTreeNode) return []

    const path = getRoutePath(routeTreeNode.originalId)

    if (!path) {
      return getRouteTreePaths(routeTreeNode.treeParent as TreeNode)
    }

    let label: string | null | undefined
    if (routeTreeNode.originalId === rootRoute?.originalId) {
      label = frontPageLabel
    } else {
      label = localeValue(locale, routeTreeNode.page?.t)
    }

    return [
      label
        ? {
            label,
            path,
          }
        : null,
      ...getRouteTreePaths(routeTreeNode.treeParent as TreeNode),
    ]
  }

  return getRouteTreePaths(route as TreeNode)
}
