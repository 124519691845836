import { graphql, PageProps } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import Article from "../components/pages/Article"

const DatoArticle = (props: PageProps<Queries.DatoArticlePageQuery, Queries.DatoArticlePageQueryVariables>) => {
  // console.debug("Rendering DatoArticle:", props)

  const { data } = props

  if (!data) {
    console.error("No data for gatsby-template datoArticle")
    return null
  }

  const { article } = data

  if (!article) {
    console.error("No article at", data)
    return null
  }

  return (
    <DefaultLayout mainMenu={data.website?.mainMenu} footer={data.website?.footer} seoMetaTags={article.seoMetaTags}>
      <Article {...article} />
    </DefaultLayout>
  )
}

export const query = graphql`
  # $originalId parametri viittaa lokalisoimattomaan route recordiin
  query DatoArticlePage($originalId: String!, $locale: String!, $fallbackLocales: [String!]) {
    website: datoCmsWebsite(locale: $locale, fallbackLocales: $fallbackLocales) {
      mainMenu {
        ...DatoMainNavTree
      }
      footer {
        ...DatoFooter
      }
    }
    article: datoCmsArticle(originalId: { eq: $originalId }, locale: $locale, fallbackLocales: $fallbackLocales) {
      ...DatoArticle
    }
  }
`

export default DatoArticle
