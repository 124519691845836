import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../context/PageContext"
import useArticlePath from "../../hooks/useArticlePath"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import { useRouteTree } from "../../hooks/useRouteTree"
import DatoContentModules from "../dato/DatoContentModules"
import DatoImage from "../dato/DatoImage"
import BgColor from "../layouts/BgColor"
import BreadCrumbs from "../molecules/BreadCrumbs"
import ShareLinks from "../molecules/ShareLinks"
import * as styles from "./Article.module.scss"

const Article = (props: Queries.DatoArticleFragment) => {
  //console.log("Rendering Article", props)
  const { originalId, locale } = useContext(PageContext)
  const getArticlePath = useArticlePath()

  const t = useDatoMicrocopy("common", locale)
  // Route record id of "Article root route" from https://harvia.admin.datocms.com/editor/item_types/522768/items/17948632/edit
  // Hard coded to avoid deprecated useWebsite()
  const routeTree = useRouteTree("17980659")

  // console.debug("Article route tree", routeTree)

  const path = getArticlePath(originalId)

  // Kategoriasivun url. Parsitaan näin, jotta toimii sekä articleCategory että Route tyyppiset sivut.
  // Asiakkas voi näin luoda oletus kategoriasivun "päälle" routen Datossa, ja siihen vapaasti sisällöt.
  const pathParts = path?.split("/") || []
  if (props.slug) {
    delete pathParts[pathParts.lastIndexOf(props.slug)]
  }
  const parentPath = `/${pathParts.filter(Boolean).join("/")}`
  const fullPathTree = [
    { label: props.title || "", path: "" },
    { label: props.articleCategory?.name || "", path: parentPath },
    ...routeTree,
  ]

  const shareText = props.excerpt || props.ingress
  const shareTitle = props.title ? `${props.title} | Harvia` : "Harvia"

  return (
    <article className={styles.container} itemScope itemType="https://schema.org/Article">
      <header>
        <div className={styles.hero}>
          <DatoImage
            {...props.mainImage}
            style={{ height: "100%" }}
            itemProp="image"
            alt={props.mainImage?.alt || ""}
          />
        </div>

        <BgColor color={props.textBackground ?? undefined} className={styles.intro}>
          <div className={styles.breadcrumbs + " layout-container"}>
            <BreadCrumbs nodes={fullPathTree} />
            {/* {props.articleTags.map((itm, i) => {
                return (
                  <React.Fragment key={i}>
                    {!!i && <span>|</span>}
                    <span className={styles.tag}>{itm.label}</span>
                  </React.Fragment>
                )
              })} */}
          </div>

          <div className="layout-container">
            <h1 itemProp="name">
              {props.title}
              {props.subTitle && (
                <>
                  <br />
                  <span>{props.subTitle}</span>
                </>
              )}
            </h1>
            {/* {props.datetime && <time itemProp="dateCreated">{new Date(props.datetime).toLocaleDateString(locale)}</time>} */}
            <p className={styles.ingress} itemProp="abstract">
              {props.ingress}
            </p>
            {shareTitle && shareText && (
              <div className={styles.share}>
                <ShareLinks
                  title={shareTitle}
                  text={shareText}
                  shareLabel={t("share", "Share")}
                  linkLabel={t("copyLink", "Copy link")}
                  emailLabel={t("sendEmail", "Send e-mail")}
                />
              </div>
            )}
          </div>
        </BgColor>
      </header>
      <div itemProp="articleBody">
        <DatoContentModules modules={props.modules} />
      </div>
    </article>
  )
}

export default Article

export const query = graphql`
  fragment DatoArticle on DatoCmsArticle {
    slug
    seoMetaTags {
      tags
    }
    articleCategory {
      originalId
      name
    }
    #articleTags {
    #  label
    #}
    datetime
    mainImage {
      ...DatoImageDefault
    }
    title
    subTitle
    ingress
    excerpt
    textBackground
    ...DatoArticleModules
  }
`
